<template>
    <div class="serveBindingManage">
        <ds-header title="数字人配置"></ds-header>
        <a-form layout="inline">
            <a-form-item label="名称：">
                <a-input v-model.trim="searchForm.name" placeholder="请输入名称" class="inputClass"/>
            </a-form-item>
            <a-form-item label="适用项目：">
                <a-select
                        style="width: 200px"
                        placeholder="全部"
                        v-model="searchForm.projectId"
                >
                    <a-select-option v-for="item in projectList" :key="item.id">
                        {{ item.project_name }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="位置：">
                <a-select
                        style="width: 200px"
                        placeholder="全部"
                        v-model="searchForm.position"
                >
                    <a-select-option v-for="item in position" :key="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="状态：">
                <a-select
                        style="width: 200px"
                        placeholder="全部"
                        v-model="searchForm.status"
                >
                    <a-select-option v-for="item in statusItem" :key="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="挂件开场动画状态：">
                <a-select
                        style="width: 200px"
                        placeholder="全部"
                        v-model="searchForm.openStatus"
                >
                    <a-select-option v-for="item in statusItem" :key="item.value">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </a-form-item>
            <span>
        <a-button type="primary" @click="search" style="margin-top: 3px"> <a-icon type="search"/>查询 </a-button>
        <a-button style="margin-left: 15px" @click="resetSearchForm">重置</a-button>
      </span>
        </a-form>
        <div style="padding-top: 15px">
            <a-button type="primary" @click="addDigitalPersonManage()">
                <a-icon type="plus"/>
                新建
            </a-button>
        </div>
        <div style="padding-top: 15px">
            <a-table
                    :rowKey="(record, index) => index"
                    :columns="table.columns"
                    :data-source="table.dataInfo"
                    :pagination="table.pagination.total ? table.pagination : false"
                    @change="pageChange"
                    bordered
            >
        <span slot="action" slot-scope="text, record" class="action">
          <a-button class="btnClass" type="link" @click="view(record)"> 查看 </a-button>
          <a-button v-if="record.status === 0" class="btnClass" type="link" @click="edit(record)"> 编辑 </a-button>
          <a-button v-if="record.status === 0" class="btnClass" type="link"
                    @click="updateStatus(record.id, 1)"> 启用 </a-button>
          <a-button v-if="record.status === 1" class="btnClass" type="link"
                    @click="updateStatus(record.id, 0)"> 禁用 </a-button>
          <a-button v-if="record.status === 1 && record.openStatus === 0" @click="updateOpenStatus(record.id, 1)"
                    class="btnClass" type="link"> 启用开场动画 </a-button>
          <a-button v-if="record.status === 1 && record.openStatus === 1" @click="updateOpenStatus(record.id, 0)"
                    class="btnClass" type="link"> 禁用开场动画 </a-button>
          <a-button v-if="record.status === 0" class="btnClass" type="link"
                    @click="deletePerson(record.id)"> 删除 </a-button>
        </span>
            </a-table>
        </div>
    </div>
</template>

<script>
import {
    deletePerson,
    getDigitalPersonList,
    updateDigitalPersonOpenStatus,
    updateDigitalPersonStatus
} from "@/api/digitalPerson";
import {mapState} from "vuex";

export default {
    name: "digitalPersonManage",
  computed: {
    ...mapState({
      projectList: (state) => state.common.projectList
    }),
  },
    data() {
        return {
            searchForm: {
                name: "",
                projectId: undefined,
                position: undefined,
                status: undefined,
                openStatus: undefined
            },
            position: [
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '首页',
                    value: 0
                },
                {
                    label: '食住行',
                    value: 1
                },
                {
                    label: '我的权益',
                    value: 2
                },
                {
                    label: '无忧创业',
                    value: 3
                },
                {
                    label: '我的周边',
                    value: 4
                },
            ],
            statusItem: [
                {
                    label: '全部',
                    value: ''
                },
                {
                    label: '启用',
                    value: 1
                },
                {
                    label: '禁用',
                    value: 0
                }
            ],
            projectName: [
                {
                    project_name: '全部',
                    id: ''
                },
            ],
            table: {
                columns: [
                    {
                        title: "名称",
                        dataIndex: "name",
                        key: "name",
                    },
                    {
                        title: "适用项目",
                        dataIndex: "projectNames",
                        key: "projectNames",
                    },
                    {
                        title: "位置",
                        dataIndex: "positions",
                        key: "positions",
                    },
                    {
                        title: "状态",
                        dataIndex: "status",
                        key: "status",
                        customRender(text) {
                            return text === 1 ? "启用" : "禁用";
                        },
                    },
                    {
                        title: "挂件开场动画状态",
                        dataIndex: "openStatus",
                        key: "openStatus",
                        customRender(text) {
                            return text === 1 ? "启用" : "禁用";
                        },
                    },
                    {
                        title: "操作人",
                        dataIndex: "operateUser",
                        key: "operateUser",
                    },
                    {
                        title: "更新时间",
                        dataIndex: "operateTime",
                        key: "operateTime",
                    },
                    {
                        title: "操作",
                        key: "action",
                        // width: "240px",
                        scopedSlots: {customRender: "action"},
                    },
                ],
                dataInfo: [],
                pagination: {
                    showSizeChanger: true,
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    showQuickJumper: true,
                    showTotal: (total) => {
                        return `共 ${total} 条`;
                    },
                },
            }
        }
    },
    mounted() {
        this.search();
    },
    created() {
    },
    methods: {
        // 搜索
        search() {
            this.table.pagination.current = 1;
            this.table.pagination.pageSize = 10;
            this.searchFormList();
        },
        // 重制
        resetSearchForm() {
            this.table.pagination.current = 1;
            this.table.pagination.pageSize = 10;
            this.searchForm = this.$options.data.call(this).searchForm;
            this.searchFormList();
        },
        //分页操作
        pageChange(option) {
            this.table.pagination.current = option.current;
            this.table.pagination.pageSize = option.pageSize;
            this.searchFormList();
        },
        // 新增数字人配置
        addDigitalPersonManage() {
            this.$router.push({
                path: '/digitalPerson/digitalPersonManage/Add',
                query: {
                    type: 'add'
                }
            })
        },
        view(record) {
            this.$router.push({
                path: '/digitalPerson/digitalPersonManage/Add',
                query: {
                    type: 'view',
                    id: record.id
                }
            })
        },
        edit(record) {
            this.$router.push({
                path: '/digitalPerson/digitalPersonManage/Add',
                query: {
                    type: 'edit',
                    id: record.id
                }
            })
        },
        async searchFormList() {
            let data = {};
            console.log(this.searchForm);
            data.name = this.searchForm.name;
            data.projectId = this.searchForm.projectId;
            data.position = this.searchForm.position;
            data.status = this.searchForm.status;
            data.openStatus = this.searchForm.openStatus;
            data.currentPage = this.table.pagination.current;
            data.pageSize = this.table.pagination.pageSize;
            const res = await getDigitalPersonList(data);
            if (res.code === '200') {
                console.log(res.data);
                this.table.dataInfo = res.data.items;
                this.table.pagination.total = res.data.total;
            }
        },
        updateStatus(id, status) {
            this.$confirm({
                title: '提示',
                content: '确定要修改状态吗？',
                onOk: async () => {
                    const res = await updateDigitalPersonStatus({id: id, status: status});
                    if (res.code === '200') {
                        this.$message.success('修改成功');
                        this.search();
                    } else {
                        this.$message.success(res.msg);
                    }
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
        updateOpenStatus(id, status) {
            this.$confirm({
                title: '提示',
                content: '确定要修改开场动画状态吗？',
                onOk: async () => {
                    const res = await updateDigitalPersonOpenStatus({id: id, status: status});
                    if (res.code === '200') {
                        this.$message.success('修改成功');
                        this.search();
                    } else {
                        this.$message.success(res.msg);
                    }
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
        deletePerson(id) {
            this.$confirm({
                title: '提示',
                content: '确定要删除吗？',
                onOk: async () => {
                    const res = await deletePerson({id: id});
                    if (res.code === '200') {
                        this.$message.success('删除成功');
                        this.search();
                    }
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
        },
    }
}
</script>

<style scoped>

</style>