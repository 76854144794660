import { fetchApi } from "@/utils/axios";
import http from "@/utils/axios";
import URL from "@/api/urlConfig"


// 新增配置
export const insertDigitalPersonConfig = (data) => {
  return fetchApi( URL.ADD_DIGITAL_PERSON_CONFIG, data, "post", 'data');
};
// 修改配置
export const updateDigitalPersonConfig = (data) => {
  return fetchApi( URL.UPDATE_DIGITAL_PERSON_CONFIG, data, "post", 'data');
};

export const getDigitalPersonList = (data) => {
  return fetchApi( URL.GET_DIGITAL_PERSON_CONFIG, data, "post", 'data', true);
}

export const selectDigitalPersonById = (data) => {
  return fetchApi( URL.SELECT_DIGITAL_PERSON_BY_ID + "/" + data, data, "get", 'data');
}

export const updateDigitalPersonStatus = (data) => {
  return fetchApi( URL.UPDATE_DIGITAL_PERSON + "/" + data.id + "/" + data.status, data, "post", 'data');
}

export const updateDigitalPersonOpenStatus = (data) => {
  return fetchApi( URL.UPDATE_DIGITAL_PERSON_OPEN_STATUS + "/" + data.id + "/" + data.status, data, "post", 'data');
}

export const deletePerson = (data) => {
  return fetchApi( URL.DELETE_DIGITAL_PERSON + "/" + data.id, data, "post", 'data');
}